.footer-bg {
    background-color: whitesmoke;
    color: black;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    column-gap: 40px;
    padding: 20px;
}
.footer-copy {
    background-color: #016400;
    color: white;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    column-gap: 40px;
    padding: 10px;
    font-weight: bold;
}
.footer-col {
    width: 330px;
}
.footer-title {
    font-weight: 700;
    font-size: 20px;
}
.footer-border {
    height: 3px;
    width: 50px;
    background-color: #0d1b25;
    margin-bottom: 20px;
}
.footer-sub {
    line-height: 2;
    margin: 3px 0px 0px 0px;
}
.footer-input {
    height: 50px;
    width: 350px;
    margin: 10px 0px;
    border: none;
    background-color: white;
}
.footer-but {
    background-color: #0d1b25;
    color: white;
    font-weight: 700;
    margin-top: 10px;
    width: 200px;
    height: 50px;
    border: none;
}
.footer-but:hover{
    background-color: #081118;
    cursor: pointer;
}
.ft-contact {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.ft-contact:hover {
    color: #016400;
}
.ft-socials{
    width: 30px;
    height: 30px;
    object-fit: fill;
    border-radius: 5px;
    cursor: pointer;
    color: #016400;
}
.footer-social{
    display: flex;
    column-gap: 10px;
}
.footer-bg p,li{
    font-size: small
}