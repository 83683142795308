.product_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
}
.product_content {
    box-shadow: #ff0000 0px 0px 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    padding: 20px;
}

.product_content_left {
    transform: translateX(5px);
}

.product_content_right {
    transform: translateX(-5px);
}
.product_content_enlarged {
    width: 350px;
    height: 350px;
    z-index: 1;
}
.product_content p {
    text-align: center;
    font-size: 11px;
    line-height: 1.5;
}
.product_content img {
    height: 100px;
    margin: 10px 0px;
}
.product_content h3 {
    color: #016400;
}

@media (max-width: 500px) {
    .product_container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        align-items: center;
        margin-top: 20px;
        padding: 20px;
        row-gap: 10px;
    }
    .product_content_enlarged {
        width: 300px;
        height: 300px;
        padding: 20px;
        grid-row-start: 0;
        grid-row-end: 1;
    }
    .product_content_left {
        transform: translateX(0px);
    }

    .product_content_right {
        transform: translateX(0px);
    }
}
