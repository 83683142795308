.hero_container {
    background-color: #016400;
    padding: 40px 20px 30px 40px;
    min-height: 350px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
.hero_container_one {
    max-width: 600px;
}
.hero_container_two {
    position: relative;
    width: 700px;
    display: flex;
    justify-content: center;
}
.hero_container_two img {
    width: 700px;
    position: absolute;
}
.hero_container p,
h1,
h3 {
    margin: 3px;
}
.go_paperless {
    font-size: 30px;
    line-height: 1.5;
}
.go_paperless_note {
    line-height: 1.5;
    color: rgb(238, 238, 238);
    font-weight: 500;
    margin: 0px;
}
.go_paperless_design {
    margin-top: -20px;
}

/* Apply to the div you want to animate */
.animated-div {
    margin-left: -20px;
    position: relative;
    animation: moveRightLeft 5s ease-in-out infinite;
}

/* Keyframes for the animation */
@keyframes moveRightLeft {
    0% {
        left: -20px;
    }
    50% {
        left: 300px; /* Move 300px to the right */
    }
    100% {
        left: -20px; /* Move back to the starting position */
    }
}

/* Apply to the div you want to animate */
.rotating-circle {
    width: 250px;
    height: 220px;
    border-radius: 50%; /* Makes the div circular */
    background-color: #fff;
    animation: rotateCircle 2s linear infinite; /* Rotate indefinitely */
    position: absolute;
}

/* Keyframes for the rotation animation */
@keyframes rotateCircle {
    0% {
        transform: rotate(0deg); /* Start at 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* Rotate to 360 degrees */
    }
}
.rotating-circle-internal {
    width: 160px;
    height: 220px;
    border-radius: 50%; /* Makes the div circular */
    background-color: #32cd32;
    position: absolute;
    animation: rotateCircleInternal 2s linear infinite; /* Rotate indefinitely */
}
@keyframes rotateCircleInternal {
    0% {
        transform: rotate(0deg); /* Start at 0 degrees */
    }
    100% {
        transform: rotate(360deg); /* Rotate to 360 degrees */
    }
}

@media (max-width: 500px) {
    .hero_container_two {
        position: static;
        width: fit-content;
        height: 150px;
        display: flex;
        justify-content: center;
    }
    .hero_container_two img {
        width: 350px;
        position: static;
        z-index: 1;
    }
    .rotating-circle {
        width: 200px;
        height: 170px;
        border-radius: 50%; /* Makes the div circular */
        background-color: #fff;
        animation: rotateCircle 2s linear infinite; /* Rotate indefinitely */
        position: absolute;
    }

    .rotating-circle-internal {
        width: 100px;
        height: 170px;
        border-radius: 50%; /* Makes the div circular */
        background-color: #32cd32;
        position: absolute;
        animation: rotateCircleInternal 2s linear infinite; /* Rotate indefinitely */
    }
    @keyframes moveRightLeft {
        0% {
            left: -20px;
        }
        50% {
            left: 100px; /* Move 300px to the right */
        }
        100% {
            left: -20px; /* Move back to the starting position */
        }
    }
}
