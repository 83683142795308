.stat_container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    margin: 10px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}

.stat_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}
.stat_content h5{
    color: #016400;
}
.stat_content p{
    font-size: small;
    line-height: 1.5;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: #a2f7a6 1px 1px;
    text-align: center;
}
.stat_circle{
    text-align: center;
    /* width: 100px;
    height: 100px;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 50px;
    color: #016400;
    /* background: radial-gradient(circle, #016400 0%, #338032 50%, #98f298 100%); */
}
.stat_circle_holder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat_dashboard_image{
    display: flex;
    justify-content: center;
    align-items: center;

}
.stat_dashboard_image img{
    width: 60%;
}
.review_container{
    display: flex;
    justify-content: center;
    padding:40px 20px 40px 20px;
    /* width:100%; */
    background-color: #016400;
    color: white;
}
.review_container_web{
    width: 60%;
}
.review_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
}
.review_content p{
    font-size: small;
}
.review_content p,h5{
    margin: 0px;
}

@media (max-width: 500px) {
    .stat_container{
        grid-template-columns: auto;
    }  
    .stat_dashboard_image img{
        width: 80%;
    }
}