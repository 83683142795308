/* src/components/partners.css */
.logo-slider {
    overflow: hidden;
    /* width: 85%; */
    margin-top: 60px;
    margin: 40px auto; 
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    animation: scroll 60s linear infinite; 
  }
  
  .logo {
    flex-shrink: 0;
    height: 40px !important;
    margin: 0 0px;
    transition: transform 0.3s;
  }
  
  .logo:hover {
    transform: scale(1.1);
  }
  .logo_name{
    font-size: medium;
    white-space: nowrap;
    color: gray;
    letter-spacing: 2px;
    margin: 0 10px;
    font-weight: 600;
  }
  .title_name{
    font-size: large;
    white-space: nowrap;
    color: #016400;
    letter-spacing: 2px;
    font-weight: bold;
    margin: 0 10px;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--total-width) / 2));
    }
  }
  
@media (max-width: 500px) {

    
  }
  