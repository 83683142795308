.onboarding_container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px;
    column-gap: 20px;
}
.onboarding_subject {
    background-color: white;
    max-width: 350px;
    line-height: 1.5;
    padding: 50px 20px;
    margin: 10px;
    z-index: 3;
    box-shadow: #ff0000 0px 0px 10px;
}
.onboarding_subject p {
    font-size: small;
}
.onboarding_image {
    margin-left: -200px;
    width: 700px;
}
.onboarding_image_container {
    position: relative;
    display: inline-block;
}

.onboarding_image_container::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("../../assets/images/general/emr.jpg") center / cover no-repeat;
    filter: blur(10px);
    z-index: 1;
}

.onboarding_image_container img {
    position: relative;
    z-index: 2;
}

.onbording_process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    background-color: white;
}
.onbording_process_item {
    width: 350px;
    background: linear-gradient(135deg, #016400, #008000);
    color: white;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    column-gap: 15px;
    box-shadow: rgb(161, 161, 161) 0px 0px 10px;
}
.onbording_process_item p {
    font-size: small;
}
.onbording_process_item p,
h4 {
    margin: 0px;
    line-height: 1.5;
}
.onbording_process_item_icon {
    font-size: 40px !important;
}

.onbording_process_item:nth-child(even) {
    background: linear-gradient(135deg, #016400, #008000);
    color: whitesmoke;
}
.aati_container_holder {
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 50px;
}
.aati_container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px;
    width: 80%;
    min-height: 350px;
    background: radial-gradient(circle, #eedad1 0%, whitesmoke 50%, #eedad1 100%);
    border-radius: 10px;
}

.aati_container_content {
    padding: 10px;
    max-width: 250px !important;
}
.aati_container_content img {
    width: 120px;
    border-radius: 50%;
}

.aati_container_content_img {
    height: 320px;
}

.onboarding_subject_holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
@media (max-width: 500px) {
    .onboarding_container {
        margin: 0px;
    }
    .onboarding_subject_holder {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .onboarding_subject_holder img {
        position: absolute;
        z-index: -1;
    }
    .onbording_process_item {
        width: 300px;
    }
    .onbording_process {
        padding-top: 20px;
        margin-top: 20px;
        width: 100%;
    }

    .onboarding_image {
        margin-left: -750px;
        position: absolute;
        z-index: -1;
        width: 700px;
    }
    .aati_container_content_img {
        /* width: 330px; */
        height: 190px;
    }
    .aati_container {
        height: max-content;
        flex-flow: row wrap;
    }
}
