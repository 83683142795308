@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.mont-norm {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.mont-sm {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.mont-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: whitesmoke; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.bg-white {
  background-color: white;
}
.fg-white {
  color: white;
}
.bg-shade-one {
  background-color: #016400 !important;
}

.bg-shade-two {
  background-color: #ff0000 !important;
}

.bg-shade-three {
  background-color: #898989 !important;
}

.bg-shade-one-dark {
  background-color: #016400 !important;
}

.bg-shade-two-dark {
  background-color: #a47619 !important;
}
.fg-shade-one {
  color: #016400 !important;
}
.fg-shade-one-dark {
  color: #016400 !important;
}
.fg-shade-two {
  color: #ff0000 !important;
}

.section_title {
  text-align: center;
  color: #016400;
  margin-top: 60px;
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-gap-mini {
  gap: 10px;
}
.flex-wrap {
  flex-flow: row wrap;
}
.hide-mobile {
  display: block !important;
}
.hide-desktop {
  display: none !important;
}
.hide {
  display: none;
}
.messenger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* background-color: #f5cccc; */
  color: #016400;
  /* width: 80px; */
  height: 80px;
  border-radius: 50%;
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  cursor: pointer;
  z-index: 5;
}
.messenger a {
  margin-top: 10px;
}

html {
  scroll-behavior: smooth;
}

.disablelink a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.button-primary {
  background-color: #016400;
  color: white;
  padding: 5px 10px;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.button-secondary {
  background-color: #ff0000;
  color: white;
  padding: 5px 10px;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.button-red {
  background-color: #ff0000;
  color: white;
  padding: 5px 10px;
  font-size: small;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.button-primary:hover {
  background-color: #047602;
}
.home_container {
  overflow-x: hidden;
}
select{
  color: dimgray;
}
@media (max-width: 500px) {
  .hide-mobile {
    display: none !important;
  }
  .hide-desktop {
    display: block !important;
  }
}
