.hd-parent {
    box-shadow: #016400 0px 0px 10px;
}
.hd-space-between {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    color: white;
    padding: 10px 20px;
}
.hd-mid-space {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 10px;
    row-gap: 10px;
}
.logo {
    height: 80px;
    margin-top: -5px;
}

.header-logo-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 6px;
}

.hd-menu {
    display: flex;
    column-gap: 50px;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px 30px 20px 30px;
}
.hd-menu p {
    margin: 5px 0px;
}
.hd-menu-con {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0px;
}
.hd-menu-line {
    background-color: #32cd32;
    margin: 0px;
    height: 2px;
    padding: 0px;
    width: 0px;
    transition: width 1s;
}

.hd-menu-con:hover {
    font-weight: bold;
    cursor: pointer;
    align-items: stretch;
}
.hd-menu-con:hover .hd-menu-line {
    width: 50px;
}
.hd-menu-main {
    background-color: 016400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* width: 80%; */
}
.hd-menu-main-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: white;
    width: 100%;
    margin-top: -1px;
    padding-bottom: 10px;
    padding: 0px 5px 10px 10px;
    background-color: #016400;
}
.hamburger {
    /* padding-right: 5px; */
    background-color: white;
    margin: 10px;
    color: #016400;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.hd-menu-bar {
    display: flex;
    justify-content: center;
    margin: 10px 10px 0px 10px;
}
.hd-menu-bar-mobile {
    display: flex;
    justify-content: stretch;
    margin: 0px 10px 0px 0px;
    width: 100%;
}
.hd-menu-mobile {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #016400;
    height: 260px;
    transition: height 1s;
}
.hd-menu-mobile-hide {
    height: 0px;
}
.hd-menu-mobile p {
    margin: 5px 0px;
}
.header-button {
    background: linear-gradient(135deg, #32cd32, #008000);
    background-size: 100% 100%;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    background-position: left;
    transition: background-position 1s ease-in-out;
}

.header-button:hover {
    background-size: 150% 150%;
    background-position: right;
}
.header-button a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    margin-top: -2px;
}
.header-button-web {
    border-radius: 5px;
}
.header-button-mobile {
    width: 100%;
}
.top-menu-align-left {
    justify-content: flex-end !important;
}
.moving-text {
    margin: 0px;
    padding-bottom: 15px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    position: absolute;
    white-space: nowrap;
    animation: bounce 20s infinite alternate ease-in-out;
    cursor: pointer;
}

/* Keyframes for bouncing effect */
@keyframes bounce {
    from {
        left: 50%;
    }
    to {
        left: -30%;
    }
    /* to { left: calc(100% - 150px); } Adjust width based on text length */
}

@media (max-width: 500px) {
    .hd-space-between {
        padding: 0px 20px 10px 20px;
    }
    .header-logo-flex {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 5px;
    }

    @keyframes bounce {
        from {
            left: 50%;
        }
        to {
            left: -100%;
        }
        /* to { left: calc(100% - 150px); } Adjust width based on text length */
    }
}
