.promo{
    height: 500px;
    z-index: 1;
}

.backdrop{
    background-image: url("../../assets/images/general/wallpaper.png");
    object-fit: fill;
    /* padding: 5px; */
    overflow: hidden;
    /* background-color: red; */
    display: flex;
    height: 530px;
}
.dark_backdrop{
    background-color: black;
    position: absolute;
    width: 100%;
    height: 530px;
    opacity: 0.3;
}
@media (max-width: 500px) {
    .promo{
        height: 350px;
    }
    .dark_backdrop{
        background-color: black;
        position: absolute;
        width: 100%;
        height: 380px;
    }
    .backdrop{
        background-image: url("../../assets/images/general/wallpaper.png");
        object-fit: fill;
        /* padding: 5px; */
        overflow: hidden;
        /* background-color: red; */
        display: flex;
        height: 380px;
    }
}